:root {
    --image-links-ratio-normal: 100%;
    --image-links-ratio-in-between: 75%;
    --image-links-ratio-wide: 50%;
}

@custom-media --image-links-columns-2-auto-rows (width > 530px);
@custom-media --image-links-columns-2-switch (width > 850px);
@custom-media --image-links-columns-3-switch (width > 850px);
@custom-media --image-links-columns-4-switch (width > 1000px);
@custom-media --image-links-columns-4-2-switch (width > 480px) and (width <= 1000px);
@custom-media --image-links-auto-columns-4-max (width >= 1600px);
@custom-media --image-links-auto-columns-3-max (width >= 1280px);
@custom-media --image-links-headliners-landscape (width >= 960px);
@custom-media --image-links-headliners-1-column (width < 640px);
@custom-media --image-links-mobile (width <= 480px);
@custom-media --image-links-live-1-column (width <= 960px);

.image-links {
    position: relative;
    display: grid;

    &.-color-line-up {
        color: var(--color-line-up);
    }

    &.-border {
        border-block-start: .4rem solid currentcolor;
        border-block-end: .4rem solid currentcolor;
    }

    &.-max-columns-1 {
        display: flex;
        justify-content: center;
    }

    &.-max-columns-2 {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        @media (--image-links-columns-2-auto-rows) {
            grid-auto-rows: 1fr;
        }

        @media (--image-links-columns-2-switch) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
        }
    }

    &.-max-columns-3 {
        grid-template-columns: 1fr;

        @media (--image-links-columns-3-switch) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &.-max-columns-4 {
        grid-template-columns: 1fr;

        @media (--image-links-columns-4-2-switch) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (--image-links-columns-4-switch) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &.-auto-columns {
        grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));

        &.-large {
            @media (--image-links-auto-columns-3-max) {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        @media (--image-links-auto-columns-4-max) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &.-headliners {
        grid-template-columns: repeat(2, 1fr);

        @media (--image-links-headliners-1-column) {
            display: block;
        }
    }

    @media (--image-links-columns-2-switch) {
        &.-shift-order .image-links__item {
            &:nth-child(1) {
                order: 1;
            }

            &:nth-child(2) {
                order: 2;
            }

            &:nth-child(3) {
                order: 4;
            }

            &:nth-child(4) {
                order: 3;
            }

            &:nth-child(5) {
                order: 5;
            }

            &:nth-child(6) {
                order: 6;
            }

            &:nth-child(7) {
                order: 8;
            }

            &:nth-child(8) {
                order: 7;
            }
        }
    }
}

.image-links__item {
    @include responsive-size(font-size, 21, 24);

    position: relative;

    overflow: hidden;
    display: block;

    margin: 0;

    font-weight: var(--font-weight-extra-bold);
    text-align: center;
    text-transform: uppercase;

    img {
        width: 100%;
        max-width: none;
        opacity: .7;
        transition: opacity .2s ease-in-out;
    }

    &.-no-overlay {
        img {
            opacity: 1;
        }
    }

    &.-green {
        color: var(--color-black);
        background-color: var(--color-primary);
    }

    &.-video {
        &::before {
            content: '';

            position: absolute;
            inset-block-start: 60%;
            inset-inline-start: 50%;
            transform: translate(-50%, -50%);

            display: block;

            width: 5rem;
            height: 5rem;

            background-image: url('../../images/play.svg');
            background-size: contain;
        }
    }
}

.image-links__item:not(.-no-overlay) {
    &:hover, &:focus, &:active {
        outline: none;

        img {
            opacity: .85;
        }

        &.-has-hover-content {
            .image-links__item-content-hover {
                display: block;
            }
        }
    }
}

.image-links__item-content {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    padding: 2em;

    > div:not([class]) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media (--image-links-mobile) {
        padding: 1em;
    }
}

.image-links__label {
    rotate: -6deg;
    
    margin-block-start: 25%;
    padding: .6em 1em;

    line-height: 1;
    color: black;

    background-color: var(--color-line-up);

    transition: rotate .15s ease-in-out;

    &.-inverse {
        rotate: 6deg;
    }

    &.-red {
        background-color: var(--color-red);
    }

    &.-green {
        background-color: var(--color-green);
    }

    &.-blue {
        background-color: var(--color-blue);
    }

    &.-yellow {
        background-color: var(--color-yellow);
    }

    &.-purple {
        background-color: var(--color-purple);
    }

    &.-orange {
        background-color: var(--color-orange);
    }

    a:hover &, a:focus & {
        rotate: 0deg;
    }
}

.image-links__item-content-hover {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;

    display: none;
    justify-content: center;

    width: 100%;
    height: 100%;
    padding: 12.8%;

    font-size: var(--font-size-s);
    font-weight: var(--font-weight-medium);
    color: var(--color-yellow);
    text-align: start;
    text-transform: none;

    background-color: rgba(0, 0, 0, .7);
}

.image-links__item-hover-title {
    margin-block-start: 0;
    font-size: var(--font-size-m);
    line-height: 1.25;
    text-transform: uppercase;
}

.image-links__item-sideinfo {
    @include responsive-size(padding-left, $inner-container-padding-min, $inner-container-padding-max);
    @include responsive-size(padding-right, $inner-container-padding-min, $inner-container-padding-max);

    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    padding-block: 5rem;

    font-size: var(--font-size-s);
    font-weight: var(--font-weight-medium);
    text-align: start;
    text-transform: none;

    > article {
        width: 100%;
        max-width: 51rem;
    }

    @media (--image-links-columns-2-auto-rows) {
        padding-block: 0;
    }
}
