.tape {
    overflow: hidden;
    display: block;

    width: calc(100% + 4px);
    height: var(--tape-height);
    margin: 0 -2px;

    font-size: var(--font-size-l);
    font-weight: var(--font-weight-extra-bold);
    line-height: var(--tape-height);
    color: var(--color-black);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: -.02em;

    background-repeat: repeat-x;
    background-position: center;

    &.-big {
        height: var(--tape-big-height);

        > svg {
            position: relative;
            inset-block-start: -1px;
            min-width: 100%;
            // Correct 1px line
            height: calc(100% + 2px);
        }
    }

    &.-sticky, &.-sticky2 {
        /* @supports (position: sticky) {
            position: sticky;
        } */

        position: fixed;
        z-index: var(--z-index-sticky-menu);
        inset-block-start: 0;
        width: 100%;
    }

    &.-sticky2 {
        inset-block-start: var(--tape-height);
    }

    &.-bgcolor-action {
        background-color: var(--color-action);
    }

    &.-bgcolor-line-up {
        background-color: var(--color-line-up);
    }

    &.-bgcolor-tickets {
        background-color: var(--color-tickets);
    }

    &.-bgcolor-sold-out {
        background-color: var(--color-sold-out);
    }

    &.-bgcolor-camping {
        background-color: var(--color-camping);
    }

    &.-bgcolor-urban-1 {
        background-color: var(--color-yellow);
    }

    &.-bgcolor-urban-2 {
        background-color: var(--color-green);
    }

    &.-bgcolor-electro-1 {
        background-color: var(--color-purple);
    }

    &.-bgcolor-electro-2 {
        background-color: var(--color-yellow);
    }

    &.-bgcolor-pop-1 {
        background-color: var(--color-green);
    }

    &.-bgcolor-pop-2 {
        background-color: var(--color-orange);
    }

    &.-bgcolor-indie-1 {
        background-color: var(--color-orange);
    }

    &.-bgcolor-indie-2 {
        background-color: var(--color-red);
    }

    &.-bgcolor-rock-1 {
        background-color: var(--color-red);
    }

    &.-bgcolor-rock-2 {
        background-color: var(--color-purple);
    }

    &.-bgcolor-red {
        background-color: var(--color-red);
    }

    &.-bgcolor-blue {
        background-color: var(--color-blue);
    }

    &.-bgcolor-yellow {
        background-color: var(--color-yellow);
    }

    &.-bgcolor-green {
        background-color: var(--color-green);
    }

    &.-bgcolor-orange {
        background-color: var(--color-orange);
    }

    &.-bgcolor-pink {
        background-color: var(--color-pink);
    }

    &.-bgcolor-purple {
        background-color: var(--color-purple);
    }

    &.-color-lineup-1 {
        background-color: var(--color-lineup-thursday);
    }

    &.-bgcolor-lineup-2 {
        background-color: var(--color-lineup-friday);
    }

    &.-bgcolor-lineup-3 {
        background-color: var(--color-lineup-saturday);
    }

    &.-bgcolor-lineup-4 {
        background-color: var(--color-lineup-sunday);
    }

    &.-bgcolor-lineup-abc {
        background-color: var(--color-red);
    }

    &.-bgcolor-instagram {
        background-color: #3F729B;
    }

    &.-bgcolor-facebook {
        background-color: #4267B2;
    }

    &.-bg-1, &.-bg-2, &.-bg-3, &.-bg-4, &.-bg-5 {
        display: flex;

        &::before, &::after {
            content: '';

            display: block;
            flex-grow: 1;

            background-repeat: repeat-x;
            background-size: auto 100%;
        }

        &::before {
            margin-inline-end: 2.5rem;
            background-position: right;
        }

        &::after {
            margin-inline-start: 2.5rem;
            background-position: left;
        }
    }

    &.-bg-1 {
        &::before, &::after {
            background-image: url('../../images/tape-bg-1.svg');
        }

        &::after {
            transform: scale(-1);
            background-position: right;
        }
    }

    &.-bg-2 {
        &::before, &::after {
            background-image: url('../../images/tape-bg-2.svg');
        }
    }

    &.-bg-3 {
        &::before, &::after {
            background-image: url('../../images/tape-bg-3.svg');
        }
    }

    &.-bg-4 {
        &::before, &::after {
            background-image: url('../../images/tape-bg-4.svg');
        }
    }

    &.-bg-5 {
        &::before, &::after {
            background-image: url('../../images/tape-bg-5.svg');
        }
    }

    &.-headliners {
        background-color: var(--color-line-up);
        background-image: url('../../images/tapes/headliners.svg');
        background-size: 33.3rem 100%;

        @media (--show-mobile-menu) {
            background-size: 28.7rem 100%;
        }
    }

    &.-subheadliners {
        background-color: var(--color-tickets);
        background-image: url('../../images/tapes/subheadliners.svg');
        background-size: 49.1rem 100%;

        @media (--show-mobile-menu) {
            background-size: 42.4rem 100%;
        }
    }
}

.tape-wrapper {
    position: relative;

    overflow-x: hidden;
    display: block;

    width: 100%;

    text-decoration: none;

    > svg {
        @media (max-width: 2000px) {
            min-width: 200rem;
        }
    }

    &.-double {
        min-height: 14.2rem;

        .tape:first-child {
            transform-origin: left;
            transform: rotate(.7deg);
        }

        .tape:last-child {
            position: absolute;
            inset-block-start: 50%;
            transform: rotate(-1deg) translate3d(0, -50%, 0);
        }

        &.-inverse {
            .tape:first-child {
                transform-origin: right;
                transform: rotate(-.7deg);

                @media (--show-mobile-menu) {
                    transform-origin: center;
                }
            }

            .tape:last-child {
                transform: rotate(1deg) translate3d(0, -50%, 0);
            }
        }

        @media (--show-mobile-menu) {
            min-height: 11.2rem;
        }
    }

    &.-jumbotron {
        position: absolute;
        z-index: 2;
        translate: 0 -15rem;
        min-height: 20rem;

        .tape:first-child {
            position: absolute;
            inset-block-end: 0;
            transform-origin: right;
            transform: rotate(.7deg);
        }

        .tape:last-child {
            position: absolute;
            inset-block-start: 65%;
            transform-origin: left;
            transform: rotate(-1deg) translate3d(0, -50%, 0);
        }
    }

    &.-rotate-up, &.-rotate-down {
        z-index: 1;
        inset-block-start: -6.2rem;
        min-height: 12.3rem;
        margin-block-end: -6.2rem;

        .tape {
            position: relative;
            inset-block-start: 3.2rem;
            transform: rotate(-1.7deg);
        }
    }

    &.-rotate-down {
        inset-block-start: 6.9rem;
        margin-block: -6.9rem 0;

        .tape {
            inset-block-start: 2.7rem;
            transform: rotate(1.7deg);

            @media (--show-mobile-menu) {
                inset-block-start: 3.4rem;
            }
        }
    }

    &.-is-title {
        inset-block-start: 0;
        margin-block: 0;
    }

    &.-margin-bottom {
        margin-block-end: 6rem;
    }

    @media (max-width: 2000px) {
        overflow: hidden;
    }
}

.tape__lines {
    fill: none;
    stroke: var(--color-black);
    stroke-linejoin: round;
}
