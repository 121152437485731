.countdown {
    display: flex;
    justify-content: space-around;

    width: 30vw;
    min-width: 32rem;
    max-width: 60rem;
    margin-block-end: 3rem;
}

.countdown__item {
    @include responsive-size(font-size, 25, 65);

    font-weight: var(--font-weight-extra-bold);
    text-align: center;

    span {
        @include responsive-size(font-size, 16, 28);

        display: block;
        font-weight: var(--font-weight-medium);
    }
}
