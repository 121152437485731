:root {
    /* stylelint-disable declaration-colon-space-after  */
    --table-border-color:               var(--color-white);

    --table-head-color:                 var(--color-black);
    --table-head-background-color:      var(--color-primary);

    --table-row-color:                  var(--color-white);
    --table-row-background-color-even:  var(--color-black);
    --table-row-background-color-odd:   var(--color-black);
    /* stylelint-enable */
}

@custom-media --table-less-padding (width < 720px);

table:not([class]), .table {
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;

    max-width: 100%;
    margin-block: 2em;

    font-size: 90%;
    overflow-wrap: anywhere;

    border: 0;

    tr {
        &:nth-child(even) {
            background-color: var(--table-row-background-color-even);
        }

        &:nth-child(odd) {
            background-color: var(--table-row-background-color-odd);
        }

        &:hover {
            td {
                color: var(--color-action);
            }
        }
    }

    th, td {
        padding: .9em .6em;
        color: var(--table-row-color);
        border-block-end: 1px solid var(--table-border-color);

        @media (--table-less-padding) {
            @include responsive-size(padding-left, 2, 6, 320, 720);
            @include responsive-size(padding-right, 2, 6, 320, 720);

            padding-block: .5em;
        }
    }

    td {
        &:first-child {
            border-inline-start: 1px solid var(--table-border-color);
        }

        &:last-child {
            border-inline-end: 1px solid var(--table-border-color);
        }
    }

    tr:last-child td {
        border-block-end-color: var(--table-border-color);
    }

    th {
        color: var(--table-head-color);
        text-align: start;
        text-transform: uppercase;
        vertical-align: middle;

        background: var(--table-head-background-color);
        border-inline-end: 1px solid var(--table-head-background-color);
        border-inline-start: 1px solid var(--table-head-background-color);
    }

    tbody {
        th, td {
            text-align: start;
            vertical-align: top;
        }
    }

    thead {
        th {
            border-block-end: 0;
        }
    }

    @media (--table-less-padding) {
        font-size: 80%;
    }
}
