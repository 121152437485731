@custom-media --band-header-move-button (width > 768px);

.band-header {
    position: relative;
}

.band-header__image {
    position: relative;
    overflow: hidden;
    margin: 0;

    img {
        width: 100%;
        max-width: none;
    }
}

.band-header__image-title {
    @include responsive-size(font-size, 18, 30);
    @include responsive-size(padding-left, $inner-container-padding-min, $inner-container-padding-max);
    @include responsive-size(padding-right, $inner-container-padding-min, $inner-container-padding-max);

    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 101%;

    color: var(--color-white);

    background-color: rgba(0, 0, 0, .3);
}

.band-header__title {
    display: block;

    margin-block-end: .4rem;

    font-weight: var(--font-weight-extra-bold);
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
}

.band-header__image-acts {
    margin-block-end: 1em;
    text-align: center;
}

@media (--band-header-move-button) {
    .band-header__back-button {
        @include responsive-size(left, $inner-container-padding-min, $inner-container-padding-max);

        position: absolute;
        inset-block-start: 5.5rem;
    }
}

.band-header__more-link {
    position: absolute;
    inset-inline-start: 50%;
    transform: translateX(-50%) rotate(90deg);

    overflow: hidden;

    width: 1.1rem;
    height: 2rem;

    text-indent: -999px;

    background-image: url('../../images/arrow-white.svg');

    transition: background-color .2s ease-in-out;

    @supports (mask-image: url('../../images/arrow.svg')) {
        background-color: white;
        background-image: none;

        mask-image: url('../../images/arrow.svg');
        mask-repeat: no-repeat;
        mask-size: auto 100%;

        &:hover, &:focus, &:active {
            background-color: var(--color-action);
        }
    }

    @media (--band-header-move-button) {
        @include responsive-size(bottom, 25, 300, 768);
    }
}
