@custom-media --band-videos-one-row (width < 800px);

.band-videos {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin-inline: auto;
}

.band-videos__item {
    flex: 1 0 30rem;
    max-width: 84rem;
}

.band-videos__embed {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-block-start: 56.25%;

    > iframe {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;

        width: 100%;
        height: 100%;
    }
}
