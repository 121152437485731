@mixin responsive-size($property, $minSize, $maxSize, $minViewportWidth: 320, $maxViewportWidth: 1920, $viewUnit: vw) {
    #{$property}: calc(#{$minSize / 10}rem + (#{$maxSize} - #{$minSize}) * (100#{$viewUnit} - #{$minViewportWidth / 10}rem) / (#{$maxViewportWidth} - #{$minViewportWidth}));

    @media (min-width: #{$maxViewportWidth}px) {
        #{$property}: #{$maxSize / 10}rem;
    }

    @media (max-width: #{$minViewportWidth}px) {
        #{$property}: #{$minSize / 10}rem;
    }
}
