@custom-media --faqs-widget-mobile (width < 500px);

.faqs-widget {
    position: relative;
    margin-block-end: 6rem;

    &.-tickets {
        background-color: var(--color-tickets);
    }

    &.-camping {
        background-color: var(--color-camping);
    }

    &.-line-up {
        background-color: var(--color-line-up);
    }
}

.faqs-widget__item {
    border-block-end: 1px solid var(--color-white);
}

.faqs-widget__item-heading {
    margin: 0;
}

.faqs-widget__item-heading-button {
    position: relative;

    display: block;

    width: 100%;
    margin: 0;
    padding: 1.45rem 4rem 1.45rem 3rem;

    font-size: 1.8rem;
    line-height: 1.62;
    color: inherit;
    text-align: start;

    appearance: none;
    background-color: transparent;
    border: 0;

    &::after {
        content: '';

        position: absolute;
        inset-block-start: 50%;
        inset-inline-end: 2.2rem;
        transform-origin: center;
        transform: rotate(90deg) translateX(-80%);

        display: block;

        width: 1.2rem;
        height: 2rem;

        background-image: url('../../images/arrow.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        transition: transform .4s ease-in-out;

        @supports (mask-image: url('../../images/arrow.svg')) {
            background-color: currentcolor;
            background-image: none;

            mask-image: url('../../images/arrow.svg');
            mask-repeat: no-repeat;
            mask-size: contain;
        }

        @media (--faqs-widget-mobile) {
            inset-inline-end: 1.2rem;
            width: .7rem;
            height: 1.2rem;
        }
    }

    &.-is-active {
        &::after {
            transform: rotate(-90deg) translateX(80%);
        }
    }

    &:focus, &:active {
        color: currentcolor;
        outline: none;
    }

    @media (--faqs-widget-mobile) {
        padding: 1.25rem 3rem 1.25rem 2.5rem;
        font-size: 1.7rem;
    }
}

.faqs-widget__item-content {
    overflow: hidden;
    max-height: 0;
    transition: max-height .4s ease-in-out;

    &.-is-active {
        max-height: 2000px;
    }
}

.faqs-widget__item-content-inner {
    padding: 1.95rem 3rem 1.45rem;

    @media (--faqs-widget-mobile) {
        padding: 1.55rem 2.5rem 1.25rem;
    }
}
