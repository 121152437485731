.header-image {
    //min-height: 25rem;
    position: relative;
}

.header-image__background {
    height: 100%;

    img {
        @include responsive-size(height, 250, 446);
        object-fit: cover;
        width: 100%;
        opacity: .7;
        background-color: #cfd5d2;
    }

    &.-no-overlay {
        img {
            opacity: 1;
        }
    }
}

.header-image__overlay {
    align-items: center;
    //background-color: rgba(0, 0, 0, .6);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    picture {
        padding: 1rem;
        position: relative;
    }
}
