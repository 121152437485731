%form__label {
    color: var(--color-action);
    cursor: pointer;
    display: block;
    line-height: 1.25;
    margin: 3rem 0 2rem;

    .form__entry--invalid & {
        // no styling needed
    }

    @media (--screen-mini-small) {
        display: block;
    }
}

label:not([class]), .form__label {
    @extend %form__label;
}

.form__label--checkbox,
.form__label--radio {
    @extend %form__label;

    color: inherit;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    margin-bottom: 1em;
    margin-top: 0;
    padding-right: 1.5em;
    position: relative;
    vertical-align: bottom;

    > input {
        margin-right: .5em;
        position: relative;
    }
}
