@custom-media --band-social-shrink (width < 720px);
@custom-media --band-social-one-line (width >= 720px);

.band-social {
    @include responsive-size(gap, 10, 40);

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;
    margin-bottom: 2rem;
}

.band-social__item {
    @include responsive-size(height, 37, 57);
    @include responsive-size(width, 37, 57);

    background-color: var(--color-white);
    border-radius: 50%;
    color: var(--color-black);
    display: flex;
    position: relative;
    transition: background-color .2s ease-in-out;

    svg {
        height: 60%;
        margin: auto;
        position: relative;
        width: 60%;
    }

    &:hover, &:focus {
        background-color: var(--color-action);
    }
}
