.menu-toggle {
    z-index: --z-index-sticky-menu-button;

    display: flex;
    align-items: center;

    height: 100%;
    margin-inline: -10px;
    padding: 0;

    color: inherit;

    appearance: none;
    background-color: transparent;
    border: 0;
    border-radius: 0;

    &:focus {
        outline: none;
    }

    &:not(.-is-active) {
        svg {
            &:last-child {
                display: none;
            }
        }
    }

    &.-is-active {
        svg {
            &:first-child {
                display: none;
            }
        }
    }

    &__label {
        font-size: 2.4rem;

        @media (--show-mobile-menu) {
            display: none;
        }
    }

    &__hamburger {
        display: flex;
        align-items: center;
        align-self: stretch;
    }
}
