html {
    scroll-behavior: smooth;

    font-family: var(--font-primary);
    font-size: 62.5%; // Work with 10px scale for rems
    color: var(--color-white);

    background-color: var(--color-black);

    &.-is-menu-open {
        @media (--show-mobile-menu) {
            overflow: hidden;
            height: 100%;
        }
    }

    @media screen and (prefers-reduced-motion: reduce) {
        scroll-behavior: auto;
    }
}
