.audio-player-trigger {
    cursor: pointer;

    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;

    overflow: hidden;

    width: 5rem;
    height: 5rem;

    text-indent: -999px;

    appearance: none;
    background-color: var(--color-red);
    background-image: url('../../images/sound.svg');
    background-position: center;
    background-size: 70%;
    border: 0;
    outline: none;

    transition: background-color .2s ease-in-out;

    &.-playing {
        background-color: var(--color-action);
        background-image: url('../../images/pause.svg');
        background-size: 43%;

        &:active, &:focus {
            background-color: var(--color-action);
        }

        &:hover {
            background-color: var(--color-tickets);
        }
    }

    &:hover, &:active, &:focus {
        background-color: var(--color-tickets);
    }
}
