.page-menu {
    width: 33.3rem;
    max-width: 100%;
    margin: 0 auto;
}

.page-menu__item {
    margin-block-end: 1.2rem;
}

.page-menu__link {
    position: relative;

    display: block;

    padding: .95rem 3rem;

    font-size: var(--font-size-m);
    font-weight: var(--font-weight-extra-bold);
    line-height: 1;
    color: var(--color-black);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    background-color: var(--color-action);
    border: 1px solid var(--color-action);

    &::after {
        content: '';

        position: absolute;
        inset-block-start: 50%;
        inset-inline-end: 1.2rem;
        transform: translateY(-50%);

        display: block;

        width: 1rem;
        height: 1.9rem;

        background-image: url('../../images/arrow.svg');
        background-size: 100% 100%;

        @supports (mask-image: url('../../images/arrow.svg')) {
            background-color: currentcolor;
            background-image: none;

            mask-image: url('../../images/arrow.svg');
            mask-repeat: no-repeat;
            mask-size: contain;
        }
    }

    &:hover, &:focus, &:active, &.-is-active {
        text-decoration: none;
        background-color: var(--color-pink);
        border-color: var(--color-pink);
    }

    &.-back {
        color: var(--color-white);
        background-color: var(--color-black);
        border-color: var(--color-white);

        &::after {
            inset-inline: 1.2rem auto;
            transform: translateY(-50%) scaleX(-1);
        }

        &:hover, &:focus, &:active {
            color: var(--color-black);
            background-color: var(--color-pink);
            border-color: var(--color-pink);
        }
    }
}
