/* Max site width without padding */
.container {
    position: relative;

    width: 100%;
    max-width: var(--outer-container-max-width);
    margin-inline: auto;
}

/* Max content width with side padding */
.container-inner {
    @extend .container;

    @include responsive-size(padding-left, $inner-container-padding-min, $inner-container-padding-max);
    @include responsive-size(padding-right, $inner-container-padding-min, $inner-container-padding-max);
}

.container-text {
    @extend .container;

    max-width: var(--text-container-max-width);

    &.-no-margin {
        margin-inline: 0;
    }

    figure {
        margin: 0 0 1em;

        &.-center {
            text-align: center;
        }

        &.-right {
            text-align: end;
        }
    }
}

.container-form {
    @extend .container;

    max-width: var(--form-container-max-width);
}

.container-button {
    @include responsive-size(padding-left, $inner-container-padding-min, $inner-container-padding-max);
    @include responsive-size(padding-right, $inner-container-padding-min, $inner-container-padding-max);

    margin-block-end: -2rem;
    padding: 5.8rem;
    text-align: center;

    .button {
        margin-inline: .8rem;
    }

    &.-no-padding-top {
        padding-block-start: 0;
    }

    &.-small-padding-top {
        padding-block-start: 2.9rem;
    }
}
