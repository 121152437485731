@import '../config.scss';

:root {
    --number-of-sticky-tapes: 0;
    --tape-height: var(--tape-height-mobile);
    --tape-big-height: var(--tape-big-height-mobile);
}

@import 'mixins/*.scss';
@import 'includes/*.scss';

@import '../Fonts/fonts.scss';
@import '../Container/container.scss';
@import '../Footer/footer.scss';
@import '../Header/header.scss';
@import '../Page/page.scss';

@import '../AudioPlayer/audio-player-trigger.scss';
@import '../Band/band-bio.scss';
@import '../Band/band-header.scss';
@import '../Band/band-social.scss';
@import '../Band/band-meta-data.scss';
@import '../Band/band-videos.scss';
@import '../CategoriesMenu/categories-menu.scss';
@import '../CampingBlocks/camping-blocks.scss';
@import '../ComponentLoader/component-loader.scss';
@import '../Cookieconsent/cookieconsent.scss';
@import '../Countdown/countdown.scss';
@import '../Cta/cta.scss';
@import '../Dialog/dialog.scss';
@import '../Embed/embed-container.scss';
@import '../Faq/faqs-widget.scss';
@import '../Form/form.scss';
@import '../Filter/filter.scss';
@import '../HeaderImage/header-image.scss';
@import '../History/history.scss';
@import '../ImageLinks/image-links.scss';
@import '../ImageLinks/image-links--live.scss';
@import '../Jumbotron/jumbotron.scss';
@import '../LanguageSwitch/language-switch.scss';
@import '../Lightbox/lightbox.scss';
@import '../Lightbox/glightbox.scss';
@import '../LineupStage/lineup-stage.scss';
@import '../LineupMenu/lineup-menu.scss';
@import '../MainLogo/main-logo.scss';
@import '../MenuToggle/menu-toggle.scss';
@import '../Nav/nav.scss';
@import '../Newsletter/newsletter.scss';
@import '../PageMenu/page-menu.scss';
@import '../Partners/partners.scss';
@import '../Schedule/schedule.scss';
@import '../SocalIcons/social-icons.scss';
@import '../Sponsors/sponsors.scss';
@import '../Tape/tape.scss';
@import '../TicketBlocks/ticket-blocks.scss';
@import '../VideoSlider/videoSlider.scss';

@import 'helpers/*.scss';
