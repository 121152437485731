@custom-media --page-one-column (width < 980px);
@custom-media --page-multi-column (width > 981px);

.page {

    @include responsive-size(padding-bottom, 20, 60);

    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin-block-start: 5.5rem;

    &.-with-menu {
        justify-content: flex-end;

        .page-content {
            @media (--page-multi-column) {
                width: auto;
                min-width: 52rem;
            }
        }

        @media (--page-one-column) {
            justify-content: center;
        }
    }

    &.-without-menu {
        @include responsive-size(margin-inline-start, 390, 443, 980);

        display: block;
        max-width: 67.2rem;

        @media (--page-one-column) {
            margin-inline: auto;
        }
    }

    &.-align-center {
        justify-content: center;
    }

    &.-roblox {
        padding-block-end: 1rem;
    }

    .page-menu {
        @include responsive-size(margin-right, 20, 110);
    }

    @media (--page-one-column) {
        display: block;

        .page-menu {
            margin: 3em auto;
        }
    }
}
