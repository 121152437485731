.history-menu {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin: 0 auto;
    padding: 3rem 2rem;

    &.-years {
        background-color: var(--color-orange);
        background-image: url('../../images/spike.svg');
        background-position-y: bottom;
        background-repeat: repeat-x;
        background-size: 20px 132px;
        padding-bottom: 16.2rem;

        .history-menu__item {
            a {
                color: currentColor;

                &:hover, &:focus {
                    color: var(--color-yellow);
                    text-decoration: none;
                }
            }

            &.-active {
                a {
                    color: var(--color-yellow);
                }
            }
        }
    }
}

.history-menu__item {
    display: inline-block;
    margin-bottom: .5rem;
    margin-right: .25rem;
    text-transform: uppercase;

    a {
        color: var(--color-yellow);
        display: inline-block;
        font-size: var(--font-size-ml);
        font-weight: var(--font-weight-extra-bold);
        line-height: 1;
        padding: 1rem 1.5rem;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        transition: color .3s ease;
        white-space: nowrap;

        &:hover, &:focus {
            color: var(--color-blue);
            text-decoration: none;
        }
    }

    &.-active {
        a {
            color: var(--color-blue);
        }
    }

    &:last-child {
        margin-right: 0;
    }
}
