.history-edition {
    width: 100%;
    margin-bottom: 4rem;

    a:not(.button) {
        color: var(--color-white);
        text-decoration: underline;

        &:hover, &:focus {
            text-decoration: none;
        }
    }
}

.history-edition__block {
    padding: 0;
    vertical-align: top;
    position: relative;
}

.history-edition__block__image {
    margin-bottom: 4rem;

    img {
        width: 100%;
        transition: transform .25s linear;
    }

    a {
        position: relative;
        display: block;
    }

    & a:hover img, & a:focus img {
        transform: scale(1.05);
    }
}

.history-edition__block__video {
    position: relative;

    iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.history-edition__block__info {
    padding: 1rem 2rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.history-edition__block__name {
    color: var(--color-orange);
    margin-bottom: 0;
}

.history-edition__poster {
    margin: 0 auto 6rem;
    max-width: 51rem;
    text-align: center;

    img {
        margin-bottom: 5rem;
    }
}

.history-edition__data {
    background-color: var(--color-orange);
    font-weight: var(--font-weight-extra-bold);
    line-height: 2;
    margin-bottom: 4rem;
    text-align: center;
    text-transform: uppercase;
    @include responsive-size(font-size, $font-size-s, $font-size-m);

    a {
        text-decoration: none;

        &:hover, &:active, &:focus {
            color: var(--color-yellow);
        }
    }

    &::before, &::after {
        background-image: url('../../images/spike.svg');
        background-position-y: bottom;
        background-repeat: repeat-x;
        background-size: 20px 132px, 20px 132px;
        content: '';
        display: block;
        height: 132px;
    }

    &::before {
        background-position-y: top;
        margin-bottom: 4.5rem;
        transform: scaleY(-1);
    }

    &::after {
        margin-top: 4.5rem;
    }
}

.history-edition__data-inner {
    @include responsive-size(padding-left, $inner-container-padding-min, $inner-container-padding-max);
    @include responsive-size(padding-right, $inner-container-padding-min, $inner-container-padding-max);
}

.history-edition__bands {
    letter-spacing: .4rem;
    max-width: 98rem;
    margin: 0 auto;
}

.history-edition__logo {
    margin-bottom: 1rem;
}

/*@media (--screen-medium-large) {
    .history-edition {
        display: table;
    }

    .history-edition__block {
        display: table-cell;
        width: 50%;
        padding: 0;
    }

    .history-edition__data {
        padding: 0;
    }

    .history-edition__poster {
        padding: 0 2rem;
        margin-bottom: 0;
    }
}*/
