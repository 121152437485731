@custom-media --schedule-hours-desktop (width > 768px);
@custom-media --schedule-hours-mobile (width < 768px);

:root {
    --schedule-color: var(--color-line-up);
}

.schedule {
    position: relative;
    padding: 0;

    &.-day-1 {
        --schedule-color: var(--color-lineup-thursday);
    }

    &.-day-2 {
        --schedule-color: var(--color-lineup-friday);
    }

    &.-day-3 {
        --schedule-color: var(--color-lineup-saturday);
    }

    &.-day-4 {
        --schedule-color: var(--color-lineup-sunday);
    }
}

.schedule__wrapper {
    position: relative;
    overflow: visible;
    padding: 0;

    @media (--schedule-hours-mobile) {
        @include responsive-size(padding-left, $inner-container-padding-min, $inner-container-padding-max);
        @include responsive-size(padding-right, $inner-container-padding-min, $inner-container-padding-max);
    }
}

@import 'schedule-acts.scss';
@import 'schedule-heading.scss';
@import 'schedule-hours.scss';
@import 'schedule-options.scss';
@import 'schedule-scroll.scss';
@import 'schedule-stage.scss';
@import 'schedule-stage-menu.scss';
