@custom-media --cta-stretch-image (width > 880px);

:root {
    --cta-max-height: 54.6rem;
}

.cta {
    position: relative;
    box-sizing: border-box;
    background-position: center;

    &.-spotify {
        height: 100%;

        background-color: var(--color-yellow);
        background-image: url('../../images/cta/bg-spotify.svg');
        background-repeat: repeat-x;
        background-size: 11.2rem 101%;

        iframe {
            @include responsive-size(height, 291, 645);
            @include responsive-size(width, 291, 645);
        }
    }

    &.-videos {
        background-color: var(--color-blue);
        background-image: url('../../images/cta/bg-video.svg');
        background-repeat: repeat-x;
        background-size: auto 100%;

        .cta__content {
            min-height: 46rem;
        }
    }

    &.-countdown {
        background-color: var(--color-purple);

        .cta__image {
            max-width: calc(max(28vw, 27rem));
        }

        .cta__background {
            background-image: url('../../images/cta/bg-countdown.svg');
        }

        @media (max-width: 550px) {
            .cta__content {
                max-width: 32rem;
            }
        }
    }
}

.cta__background {
    &.-fixed-height {
        height: 25rem;
        background-position: center;
        background-size: 90rem 100%;

        @media (--cta-stretch-image) {
            height: 0;
            padding-block-start: 28.44%;
            background-size: contain;
        }

        @media (--exceeding-container-max) {
            height: var(--cta-max-height);
            padding-block-start: 0;
        }
    }
}

.cta__inner {
    &.-fixed-height {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;

        width: 100%;
        height: 100%;
    }
}

.cta__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100%;
    padding-block: 3rem;

    text-align: center;
}

.cta__title {
    @include responsive-size(font-size, 20, 95);

    position: relative;

    display: block;

    margin-block: 0 2rem;

    font-weight: var(--font-weight-extra-bold);
    line-height: 1.25;
    text-transform: uppercase;

    &.-no-margin-bottom {
        margin-block-end: 0;
    }

    a {
        color: inherit;
        text-decoration: none;

        &:hover, &:focus, &:active {
            color: var(--color-secondary);
            text-decoration: none;
        }
    }
}
