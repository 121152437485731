.h-margin-top-4-responsive {
    @include responsive-size(margin-top, 10, 40);
}

.h-margin-top-2 {
    margin-block-start: 2rem;
}

.h-margin-top-4 {
    margin-block-start: 4rem;
}

.h-margin-top-5 {
    margin-block-start: 5rem;
}

.h-margin-bottom-5 {
    margin-block-end: 5rem;
}

.h-margin-bottom-8 {
    margin-block-end: 8rem;
}

.h-page-margin-bottom {
    @include responsive-size(margin-bottom, 20, 60);
}
