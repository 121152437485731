.nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    li {
        &.-is-active {
            a {
                color: var(--color-action);
            }
        }

        a {
            display: block;
            text-decoration: none;
            text-transform: uppercase;

            &:hover, &:focus, &:active {
                color: var(--color-action);
            }
        }
    }

    &.-primary {
        @include responsive-size(font-size, 24, 28);

        font-weight: var(--font-weight-extra-bold);

        li {
            @include responsive-size(margin-block-end, 20, 50);
        }

        @media (--show-desktop-menu) {
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 2em;
            justify-content: center;
        }
    }
}

.nav-wrapper {
    display: flex;
    justify-content: space-between;
}
