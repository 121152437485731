@custom-media --button-make-bigger (width > 750px);

%button {
    cursor: pointer;

    position: relative;

    display: inline-block;

    margin-block-end: 2rem;
    padding: .8rem 2rem .9rem;

    font-size: var(--font-size-m);
    font-weight: var(--font-weight-extra-bold);
    line-height: 1;
    color: var(--color-black);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    background-color: var(--color-action);
    border: 1px solid var(--color-action);

    transition: background-color .2s ease-in-out, color .2s ease-in-out, border-color .2s ease-in-out;

    &.-no-margin {
        margin-block-end: 0;
    }

    &.-transparent {
        color: var(--color-white);
        background-color: transparent;
        border: 1px solid var(--color-white);
    }

    &.-black {
        color: var(--color-white);
        background-color: var(--color-black);
        border-color: var(--color-black);
    }

    &:hover, &:focus, &:active {
        color: var(--color-black);
        text-decoration: none;
        background-color: var(--color-pink);
        border-color: var(--color-pink);
    }

    &.-filter {
        margin-block-end: 0;
        padding: 1rem 1.8rem !important;
        background-color: var(--color-white);
        border-color: var(--color-white);

        &:hover, &:focus, &:active {
            background-color: var(--color-pink);
            border-color: var(--color-pink);
        }
    }

    &.-size-l {
        font-size: var(--font-size-l);
    }

    &.-color-orange {
        background-color: var(--color-orange);
        border-color: var(--color-orange);

        &:hover, &:focus, &:active {
            background-color: var(--color-yellow);
            border-color: var(--color-yellow);
        }
    }

    &.-arrow {
        &::after {
            content: '';

            position: relative;
            inset-block-start: 2px;

            display: inline-block;

            width: 1rem;
            height: 1.8rem;
            margin-inline-start: .8em;

            background-color: currentcolor;

            mask-image: url('../../../images/arrow.svg');
            mask-repeat: no-repeat;
            mask-size: contain;
        }
    }

    @media (--button-make-bigger) {
        &:not(.-secondary) {
            padding-block: 1.3rem 1.4rem;
        }
    }
}

button:not([class]), .button {
    @extend %button;

    &.-padding-large {
        padding: 1.45rem 4rem;
    }
}

@supports (mask-image: url('../../../images/print.svg')) {
    .button.-icon-print {
        &::after {
            content: '';

            position: relative;
            inset-block-start: .2rem;

            display: inline-block;

            width: 1.9rem;
            height: 1.9rem;
            margin-inline-start: .5em;

            background-color: currentcolor;

            mask-image: url('../../../images/print.svg');
            mask-repeat: no-repeat;
            mask-size: contain;
        }
    }
}

.button.-back {
    padding-inline-start: 4.4rem;
    color: var(--color-white);
    background-color: transparent;
    border: 1px solid var(--color-white);

    &::after {
        content: '';

        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 1.2rem;
        transform: translateY(-50%) scaleX(-1);

        display: block;

        width: 1rem;
        height: 1.9rem;

        background-image: url('../../../images/arrow-white.svg');
        background-size: 100% 100%;

        @supports (mask-image: url('../../../images/arrow.svg')) {
            background-color: currentcolor;
            background-image: none;

            mask-image: url('../../../images/arrow.svg');
            mask-repeat: no-repeat;
            mask-size: contain;
        }
    }

    &:hover, &:focus, &:active {
        color: var(--color-black);
        text-decoration: none;
        background-color: var(--color-pink);
        border-color: var(--color-pink);

        &::after {
            @include target-ie() {
                background-image: url('../../../images/arrow.svg');
            }
        }
    }
}

.anchor-bouttons, .anchor-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-start;

    margin-block-end: 3em;

    .button {
        margin-block-end: 0;
        padding: .7rem 1.5rem .9rem;
        font-size: var(--font-size-s);
    }
}
