@custom-media --lineup-stage-5-columns (width >= 1750px);
@custom-media --lineup-stage-4-columns (width >= 1400px) and (width < 1750px);
@custom-media --lineup-stage-3-columns (width >= 1050px) and (width < 1400px);
@custom-media --lineup-stage-2-columns (width >= 700px) and (width < 1050px);
@custom-media --lineup-stage-1-columns (width < 700px);

:root {
    --lineup-stage-color: var(--color-primary);
}

.lineup-stages {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(35rem, 1fr));

    &.-day-1 {
        --lineup-stage-color: var(--color-lineup-thursday);
    }

    &.-day-2 {
        --lineup-stage-color: var(--color-lineup-friday);
    }

    &.-day-3 {
        --lineup-stage-color: var(--color-lineup-saturday);
    }

    &.-day-4 {
        --lineup-stage-color: var(--color-lineup-sunday);
    }
}

.lineup-stage {
    display: flow-root;
    color: var(--color-white);
    background-color: var(--color-black);

    @media (--lineup-stage-1-columns) {
        &:nth-child(2n) {
            @supports (mask-image: url('../../images/stages/bg-boiler-room.svg')) {
                color: var(--color-black);
                background-color: var(--lineup-stage-color);
            }
        }
    }

    @media (--lineup-stage-2-columns) {
        &:nth-child(4n-1), &:nth-child(4n-2) {
            @supports (mask-image: url('../../images/stages/bg-boiler-room.svg')) {
                color: var(--color-black);
                background-color: var(--lineup-stage-color);
            }
        }
    }

    @media (--lineup-stage-3-columns) {
        &:nth-child(2n) {
            @supports (mask-image: url('../../images/stages/bg-boiler-room.svg')) {
                color: var(--color-black);
                background-color: var(--lineup-stage-color);
            }
        }
    }

    @media (--lineup-stage-4-columns) {
        &:nth-child(8n-6), &:nth-child(8n-4), &:nth-child(8n-3), &:nth-child(8n-1) {
            @supports (mask-image: url('../../images/stages/bg-boiler-room.svg')) {
                color: var(--color-black);
                background-color: var(--lineup-stage-color);
            }
        }
    }

    @media (--lineup-stage-5-columns) {
        &:nth-child(2n) {
            @supports (mask-image: url('../../images/stages/bg-boiler-room.svg')) {
                color: var(--color-black);
                background-color: var(--lineup-stage-color);
            }
        }
    }
}

.lineup-stage__title {
    @extend .container-inner;

    position: relative;
    z-index: 0;

    display: flex;
    flex-basis: calc(100% / 3);
    align-items: center;
    justify-content: left;

    margin-block: 4rem 0;

    font-size: var(--font-size-m);
    text-align: center;
    text-transform: uppercase;

    &::before {
        margin-inline-end: 1em;
    }

    &::before {
        content: '';

        display: inline-block;

        width: 5rem;
        height: 5rem;

        background-color: currentcolor;

        mask-repeat: no-repeat;
        mask-size: contain;
    }

    &.-main-stage {
        &::before {
            mask-image: url('../../images/stages/icon-main-stage.svg');
        }
    }

    &.-boiler-room {
        &::before {
            mask-image: url('../../images/stages/icon-boiler-room.svg');
        }
    }

    &.-dance-hall {
        &::before {
            mask-image: url('../../images/stages/icon-dance-hall.svg');
        }
    }

    &.-marquee {
        &::before {
            mask-image: url('../../images/stages/icon-marquee.svg');
        }
    }

    &.-castello {
        &::before {
            mask-image: url('../../images/stages/icon-castello.svg');
        }
    }

    &.-club {
        &::before {
            mask-image: url('../../images/stages/icon-club.svg');
        }
    }

    &.-booth {
        &::before {
            mask-image: url('../../images/stages/icon-booth.svg');
        }
    }

    &.-lift {
        &::before {
            mask-image: url('../../images/stages/icon-lift.svg');
        }
    }

    &.-secret-room {
        &::before {
            mask-image: url('../../images/stages/icon-secret-room.svg');
        }
    }

    &.-backyard {
        &::before {
            mask-image: url('../../images/stages/icon-backyard.svg');
        }
    }

    &.-vall-ey {
        &::before {
            mask-image: url('../../images/stages/icon-vall-ey.svg');
        }
    }

    &.-e-rena {
        &::before {
            mask-image: url('../../images/stages/icon-e-rena.svg');
        }
    }
}

.lineup-stage__content {
    @extend .container-inner;

    display: flex;
    flex-basis: calc(100% / 3 * 2);
    align-items: center;
    justify-content: flex-start;

    padding-block: 4rem;

    font-size: var(--font-size-s);
    font-weight: var(--font-weight-extra-bold);
}

.lineup-stage__list {
    max-width: 47.5rem;
    text-transform: uppercase;

    a {
        text-decoration: none;
        transition: none;
    }
}

.lineup-stage__list-item {
    display: block;
    margin: 1rem 0;
    line-height: 1.2;

    &:first-child {
        margin-block-start: 0;
    }

    &:last-child {
        margin-block-end: 0;
    }
}

.lineup-stage-act__timings {
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-regular);
}
