.header {
    position: relative;
    z-index: var(--z-index-header);

    .-is-menu-open & {
        display: flex;
        flex-direction: column;

        @media (--show-mobile-menu) {
            width: 100%;
            height: 100dvh;
        }
    }

    @media (--show-mobile-menu) {
        position: sticky;
        z-index: var(--z-index-sticky-menu);
        inset-block-start: 0;
        background-color: var(--color-black);
    }
}

.header__top {
    width: 100%;
    color: var(--color-white);

    .-is-menu-open & {
        color: var(--color-black);
        background-color: var(--color-action);
    }
}

.header__top-menu {
    .-is-menu-open & {
        .button {
            background-color: var(--color-purple);

            &:hover, &:active, &:focus {
                background-color: var(--color-white);
                border-color: var(--color-white);
                color: var(--color-black);
            }
        }
    }
}

.header__top-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--tape-height);

    .header__top-menu {
        display: flex;
        column-gap: 3rem;
        align-items: center;

        .button {
            margin-block-end: 0;

            @media (max-width: 34rem) {
                display: none;
            }
        }
    }

    @media (--show-desktop-menu) {
        height: calc(2 * var(--tape-height));
    }
}

.header__top-right {
    display: none;
    flex: 1 0 auto;
    justify-content: flex-end;
    margin-inline-start: 5rem;

    .social-icons {
        margin-block-end: 0;
    }

    > *:not(:last-child) {
        margin-inline-end: 5rem;
    }
}

.header__banner {
    margin-block-end: -1px;

    .-is-menu-open & {
        display: none;
    }
}

.header__bottom {
    display: none;
    flex: 1;
    background-color: var(--color-black);

    .-is-menu-open & {
        display: block;
    }

    @media (--show-desktop-menu) {
        position: absolute;
        inset-block-start: 10rem;
        width: 100%;
    }

    @media (--show-mobile-menu) {
        .tape-wrapper {
            display: none;
        }
    }
}

.header__bottom-inner {
    overflow: hidden;
    align-items: center;
    justify-content: space-between;

    height: var(--tape-height);
    padding-block: 2em 2.5em;

    &.-no-border {
        border-block-start: 0;
    }

    .-is-menu-open & {
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        height: 100%;

        > *:not(:last-child) {
            margin-block-end: 3vh;
        }

        > *:nth-last-child(2) {
            flex: 1 0 auto;
            justify-content: flex-start;
            width: 100%;

            @media (--show-desktop-menu) {
                justify-content: center;
                text-align: center;
            }
        }

        @media (--show-desktop-menu) {
            align-items: center;
            justify-content: center;
        }
    }
}

.header__bottom-mobile {
    display: block;

    .social-icons {
        margin-block-end: 1em;

        @media (--show-desktop-menu) {
            margin-block-end: 3em;
        }
    }
}
