.image-links--live {
    @extend .image-links;

    grid-auto-rows: 1fr;

    img {
        width: 100%;
        height: 100%;
    }

    .image-links__item {
        font-weight: var(--font-weight-semi-bold);
        text-transform: initial;

        &.-has-act {
            font-weight: var(--font-weight-extra-bold);
        }
    }

    .image-links__item-content {
        align-items: flex-end;

        > div:not([class]) {
            align-items: flex-start;
            text-align: start;
        }
    }

    .image-links__meta {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;

        display: flex;

        min-height: 3.2rem;

        font-size: 1.3rem;
        color: var(--color-white);

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 3.2rem;
            height: 3.2rem;

            &.-facebook, &.-youtube, &.-twitter, &.-instagram {
                background-color: var(--color-purple);
            }

            > svg {
                display: inline-block;
                width: auto;
                height: 2.2rem;
            }
        }

        &-live {
            display: flex;
            align-items: center;

            padding: 0 .8rem;

            font-weight: var(--font-weight-semi-bold);

            background-color: var(--color-red);
        }

        &-label {
            display: flex;
            align-items: center;
            padding: 0 .8rem;
            background-color: var(--color-black);
        }
    }

    .image-links__play-button {
        display: flex;
        align-items: center;
        align-self: center;
        justify-content: center;

        width: 5rem;
        height: 5rem;
        margin-block-end: 1em;

        background-color: var(--color-yellow);
        border-radius: 50%;

        transition: background-color .15s ease-in-out;

        &::before {
            content: '';

            position: relative;
            inset-inline-start: 2px;

            display: inline-block;

            width: 0;
            height: 0;

            border-block-start: 1.3rem solid transparent;
            border-block-end: 1.3rem solid transparent;
            border-inline-start: 2.2rem solid black;
            border-inline-end: 0;
        }
    }

    a:hover {
        .image-links__play-button {
            background-color: var(--color-blue);
        }
    }

    @media (min-width: 42rem) {
        grid-template-columns: repeat(auto-fill, minmax(42rem, 1fr));
    }
}
