body {
    @include font-smoothing(); // Disable font-smoothing on places where you don't want it

    font-size: var(--font-size-s);
    line-height: 1.5;

    .-is-menu-open & {
        @media (--show-mobile-menu) {
            overflow: hidden;
            height: 100%;
        }
    }
}
