.language-switch {
    margin-inline: -1em;

    a {
        position: relative;

        display: inline-block;

        padding: 0 1em;

        font-weight: var(--font-weight-semi-bold);
        text-decoration: none;

        &:not(:last-child)::after {
            content: '';

            position: absolute;
            inset-block-start: 0;
            inset-inline-end: -.1rem;

            width: .1rem;
            height: 100%;

            background-color: var(--color-white);
        }

        &:hover, &:focus, &:active {
            color: var(--color-action);
        }
    }

    @media (--show-desktop-menu) {
        text-align: center;
    }
}
