//noinspection Stylelint
$font-size-xl: 34;
$font-size-l: 30;
$font-size-ml: 25;
$font-size-m: 21;
$font-size-s: 16;
$font-size-xs: 14;

$inner-container-padding-min: 20;
$inner-container-padding-max: 58;

//noinspection ALL
:root {
    --color-black:                      black;
    --color-gray:                       #5F5F5F;
    --color-white:                      white;
    --color-red:                        #FF402C;
    --color-orange:                     #FFA300;
    --color-yellow:                     #E5FF1C;
    --color-pink:                       var(--color-purple);
    --color-purple:                     #AD6EFF;
    --color-blue:                       #4299F7;
    --color-green:                      #16E589;

    --color-primary:                    var(--color-yellow);

    --color-sold-out:                   var(--color-red);
    --color-action:                     var(--color-green);
    --color-tickets:                    var(--color-blue);
    --color-line-up:                    var(--color-yellow);
    --color-camping:                    var(--color-purple);

    --color-facebook:                   #3b5998;
    --color-youtube:                    #ff0000;
    --color-twitter:                    #00acee;
    --color-instagram:                  #3f729b;

    --color-lineup-thursday:            var(--color-green);
    --color-lineup-friday:              var(--color-purple);
    --color-lineup-saturday:            var(--color-orange);
    --color-lineup-sunday:              var(--color-blue);

    --font-primary:                     'Montserrat', sans-serif;

    --font-weight-regular:              400;
    --font-weight-medium:               500;
    --font-weight-semi-bold:            600;
    --font-weight-bold:                 700;
    --font-weight-extra-bold:           800;

    --font-size-xl:                     #{$font-size-xl / 10}rem;
    --font-size-l:                      #{$font-size-l / 10}rem;
    --font-size-ml:                     #{$font-size-ml / 10}rem;
    --font-size-m:                      #{$font-size-m / 10}rem;
    --font-size-s:                      #{$font-size-s / 10}rem;
    --font-size-xs:                     #{$font-size-xs / 10}rem;

    --outer-container-max-width:        2100px;
    --inner-container-max-width:        2100px;
    --form-container-max-width:         50rem;
    --text-container-max-width:         42em;
    --tape-height:                      5.8rem;
    --tape-height-mobile:               5rem;
    --tape-big-height:                  11.6rem;
    --tape-big-height-mobile:           10rem;

    --z-index-header:                   3;
    --z-index-jumbotron-tape:           2;
    --z-index-sticky-menu:              100;
    --z-index-sticky-menu-button:       101;
    --z-index-filter-dropdown:          11;
}

@custom-media --show-mobile-menu (width < 910px);
@custom-media --show-desktop-menu (width >= 910px);
@custom-media --exceeding-container-max (width > 1920px);
