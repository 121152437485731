@import '../../node_modules/@inventis/cookieconsent/src/cookie-consent.scss';

:root {
    --cookie-consent-background-color: var(--color-yellow);
    --cookie-consent-color: var(--color-black);
    --cookie-consent-options-background-color: rgba(255, 255, 255, .7);
    --cookie-consent-iframe-template-background-color: var(--color-blue);
}

.cookie-consent {
    @include responsive-size(padding, 20, 40, 470, 730);

    z-index: 999;

    p {
        a {
            &:focus {
                outline: none;
            }
        }
    }
}

.cookie-consent__options {
    label {
        font-weight: bold;
    }

    input[type='checkbox'] {
        margin-inline-end: .5rem;
    }
}

.cookie-consent__buttons {
    flex-wrap: wrap;
    gap: 1rem .5rem;

    &:not([hidden]) {
        display: flex;
    }

    .button {
        margin-block-end: 0;
    }
}

.cookie-consent__options-toggle {
    font-weight: bold;
}

.cookie-consent__option-description {
    font-weight: normal;
}

template.-pending-consent {
    @include responsive-size(font-size, 15, 30);

    &[data-consent-type='video'] {
        &::before {
            content: '';
        }

        &::after {
            content: '';

            position: absolute;
            inset: 0;

            display: block;

            background-image: url('../../images/no-play.svg');
            background-position: center;
            background-size: 5rem;
        }
    }
}
