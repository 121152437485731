.jumbotron {
    position: relative;
    height: calc(min(40vw, 96rem));
    min-height: 25rem;
    max-height: 82rem;

    &::before {
        content: '';

        position: absolute;
        z-index: 2;
        inset: 0;

        display: block;

        background: transparent;

        @media (--show-desktop-menu) {
            background: linear-gradient(180deg, rgba(0, 0, 0, .6) 0%, transparent 10rem, transparent 100%);
        }
    }

    @media (--show-desktop-menu) {
        margin-block-start: calc(-2 * var(--tape-height));
    }

    @media (--show-mobile-menu) {
        margin-block-end: 4rem; // create space for tapes without losing to much overlap
    }
}

.jumbotron__inner {
    @extend .container-inner;

    height: 100%;
}

.jumbotron__video, .jumbotron__image {
    position: absolute;
    inset: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
}

.jumbotron__video {
    overflow: hidden;
}

.jumbotron__video-player {
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    transform: translate(-50%, -50%);

    min-width: 60rem; // fix for mobile
    max-width: none;
    min-height: 132%; // fix for mobile
}

.jumbotron__tape {
    position: absolute;
    z-index: var(--z-index-jumbotron-tape);
    inset: 0;
    overflow-x: hidden;

    .tape-wrapper.-first {
        position: absolute;
        z-index: 1;
        inset-block-end: 0;
        transform-origin: left;
        rotate: -3deg;

        width: calc(100% + 2rem);
    }

    .tape-wrapper.-second {
        position: absolute;
        inset-block-end: 0;

        @media (--show-mobile-menu) {
            inset-block-end: 0;

            .tape.-big {
                height: var(--tape-height);
            }
        }
    }
}
